import './style.scss'
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import Flip from 'gsap/Flip'

console.log(
  `%c☀️ Hello and welcome !\n%cI'm glad you are here :)`,
  'font-weight: bold; color: #53F6F3; ',
  'color: #02FF9A'
)
/**
 * -----------------------------------------------------------------------------
 * Goodies must match containers number and order
 *
 * TODO :
 *   - CHECK Project txts and alt imgs
 *   - Seperate JS in multiple files
 *   - Create loader
 *   - Clean Social Networks
 *   - Add credits (Antoine, unsplash)
 *   - Check Memory leaks
 * -----------------------------------------------------------------------------
 */

gsap.registerPlugin(Observer, Flip)
gsap.defaults({ overwrite: 'auto' })

type TScreenType = 'portfolio' | 'about' | 'hire-me' | 'case-study'

document.addEventListener('DOMContentLoaded', () => {
  const logo = document.querySelector('#logo')!

  const header = document.querySelector('#header')!
  const title = header.querySelector('#title')!
  const year = header.querySelector('#year')!
  const subtitle = header.querySelector('#subtitle')!

  const caption = document.querySelector('#caption')!

  // Screens
  const about = document.querySelector('#about')!
  const aboutContent = document.querySelector('#about-content')!
  const aboutBtn = document.querySelector('#about-btn')!

  // const portfolio = document.querySelector('#portfolio')!
  // const portfolioContent = document.querySelector('#portfolio-content')!
  const portfolioBtn = document.querySelector('#portfolio-btn')!

  const hireMe = document.querySelector('#hire-me')!
  const hireMeContent = document.querySelector('#hire-me-content')!
  const hireMeBtn = document.querySelector('#hire-me-btn')!

  const caseStudy = document.querySelector('#case-study')!
  const caseStudyContent = document.querySelector('#case-study-content')!

  const main = document.querySelector('#main')!
  const track = document.querySelector('#track')!
  const containers = document.querySelectorAll('.container')

  const explore = document.querySelector('#explore')!
  const scrollIndicator = document.querySelector('#scroll-indicator')!

  // Settings
  const initialBgColor = '#242424'
  const sensitivity = 0.1
  const zoomFactor = 8
  const gap = 20
  const minScroll = -100
  const maxScroll = 0
  const effectRadius = 3 // Number of conatiners around the center one affected by effect
  const depthEffectFactor = 400
  const rotationEffectFactor = 90
  const idleDelay = 3

  const nbContainers = containers.length
  const trackWidth = nbContainers * 120
  const containerWidth = (trackWidth - gap * (nbContainers - 1)) / nbContainers
  let isZoomed = false
  let selectedContainer: HTMLDivElement | null = null
  let currentCaseStudyName: string | undefined = undefined
  let currentCaseStudyLink: string | undefined = undefined
  let scrollTimeout: number
  let currentPercent = calculateContainerCenterPercentage(0, minScroll, maxScroll)

  let currentScreen: TScreenType = 'portfolio'

  // --------------------------------------------------------------------------- Helpers
  function clamp(value: number) {
    return Math.min(Math.max(value, minScroll), maxScroll)
  }

  function calculateContainerCenterPercentage(id: number, a: number, b: number): number {
    const centerPosition = id * (containerWidth + gap) + containerWidth / 2
    const normalizedPosition = centerPosition / trackWidth
    const percentageInScale = a + normalizedPosition * (b - a)
    const invertedPercentage = b - (percentageInScale - a)

    return invertedPercentage
  }

  function getCenteredContainerIndex(): number {
    const rangePerContainer = Math.abs(maxScroll - minScroll) / nbContainers
    const index = Math.floor(Math.abs(currentPercent - maxScroll) / rangePerContainer)
    return Math.max(0, Math.min(nbContainers - 1, index))
  }

  function resetLayout() {
    containers.forEach((el) => resetElement(el))
    resetTxts()
  }

  function resetTxts() {
    title.innerHTML = ''
    subtitle.innerHTML = ''
    year.innerHTML = ''
    caption.innerHTML = ''
  }

  function resetElement(el: Element) {
    gsap.to(el as HTMLDivElement, {
      translateZ: 0,
      rotateY: 0,
      duration: 0.5,
      ease: 'power3.out',
    })
  }

  function animateContainers(delta: number) {
    const centerContainerIndex = getCenteredContainerIndex()

    containers.forEach((container, index) => {
      const distance = Math.abs(index - getCenteredContainerIndex())
      const isCenter = distance === 0

      if (distance <= effectRadius) {
        const effectFactor = Math.abs(Math.min(2, Math.max(-2, delta)))

        const translateZ = isCenter
          ? depthEffectFactor
          : Math.max(0, depthEffectFactor * (1 - distance / effectRadius) * effectFactor)

        const rotateY = isCenter
          ? 0
          : (index < centerContainerIndex ? -1 : 1) * rotationEffectFactor * (1 - distance / effectRadius)

        gsap.to(container, {
          translateZ,
          rotateY,
          duration: 0.2,
          ease: 'power3.out',
        })
      }
    })

    // Reset inactivity timer
    clearTimeout(scrollTimeout)
    scrollTimeout = setTimeout(() => resetLayout(), idleDelay)
  }

  function setBgColor(color: string) {
    gsap.to(main, { backgroundColor: color, duration: 3, ease: 'power3.out' })
  }

  function setInfos(container: HTMLDivElement, active: boolean) {
    const logo = container.querySelector('.logo')

    const titleTxt = container.dataset.title!
    const subtitleTxt = container.dataset.subtitle!
    const yearTxt = container.dataset.year!
    const captionTxt = container.dataset.caption!

    const timeline = gsap.timeline({ defaults: { ease: 'power3.out', duration: 0.8 } })

    if (active) {
      title.innerHTML = ''
      subtitle.innerHTML = subtitleTxt
      year.innerHTML = yearTxt
      caption.innerHTML = captionTxt

      const titleLetters = [...titleTxt]
      titleLetters.forEach((letter) => {
        const letterWrapper = document.createElement('span')
        letterWrapper.textContent = letter
        letterWrapper.classList.add('letter')
        title.appendChild(letterWrapper)
      })

      timeline
        .set(title, { opacity: 1 })
        .set(subtitle, { y: -100 })
        .set(year, { y: -50 })
        .set(caption, { y: 50 })
        .set('.container', { pointerEvents: 'none' })
        .fromTo('.letter', { y: -150, opacity: 0 }, { y: 0, opacity: 1, duration: 1, stagger: 0.03 })
        .to(logo, {
          y: -200,
          duration: 0.3,
          delay: -1,
          scale: 1.2,
          ease: 'power1.out',
          onComplete: () => {
            gsap.to(logo, { y: -100, scale: 1, zIndex: 3, duration: 0.2 })
          },
        })
        .to(subtitle, { y: 0, opacity: 1, delay: -1 })
        .to(year, { y: 0, opacity: 1, delay: -0.9 })
        .to(caption, { y: 0, opacity: 1, delay: -1 })
    } else {
      timeline
        .to('.letter', {
          y: -150,
          opacity: 0,
          duration: 0.5,
          stagger: 0.03,
          onComplete: () => {
            title.innerHTML = ''
          },
        })
        .to(logo, {
          y: -200,
          scale: 1.2,
          duration: 0.1,
          delay: -0.5,
          ease: 'power1.out',
          onComplete: () => {
            gsap.set(logo, { zIndex: 0 })
            gsap.to(logo, {
              y: 0,
              duration: 0.2,
              scale: 0,
              ease: 'power1.out',
            })
          },
        })
        .to(subtitle, { y: -100, opacity: 0, duration: 0.3, delay: -1 })
        .to(year, { y: -50, opacity: 0, duration: 0.3, delay: -1 })
        .to(caption, { y: 50, opacity: 0, duration: 0.3, delay: -1 })
        .set('.container', { pointerEvents: 'auto' })
    }
  }

  // --------------------------------------------------------------------------- Animations
  function handleTranslateTrack(data: any) {
    if (currentScreen !== 'portfolio') return
    let delta

    if (isZoomed) zoomOut()
    if (data.event.type === 'wheel') delta = data.deltaY * sensitivity + data.deltaX * sensitivity
    else if (data.event.type === 'pointermove') delta = (-data.deltaX * sensitivity) / 2
    else delta = data.deltaY * sensitivity + data.deltaX * sensitivity * -2

    currentPercent = clamp(currentPercent - delta)

    gsap.to(track, { xPercent: currentPercent, duration: 1.3, ease: 'power3.out' })
    // gsap.from('.container', { y: 0, duration: 1, ease: 'power3.out', stagger: 0.2 })
    animateContainers(delta)
  }

  function setExploreText() {
    if (currentCaseStudyLink !== undefined) explore.innerHTML = 'Visit Website'
    else explore.innerHTML = 'Explore'
  }

  function zoomIn(container: HTMLDivElement) {
    if (currentScreen !== 'portfolio') switchScreen('portfolio')
    if (isZoomed) return
    isZoomed = true
    selectedContainer = container

    currentCaseStudyName = selectedContainer?.dataset?.name
    currentCaseStudyLink = selectedContainer?.dataset?.link
    setExploreText()

    selectedContainer.classList.add('active')
    const index = parseInt(selectedContainer.dataset.id || '0', 10)
    currentPercent = calculateContainerCenterPercentage(index, minScroll, maxScroll)
    setBgColor(selectedContainer.dataset.bgColor || initialBgColor)
    setInfos(selectedContainer, true)
    gsap.to(track, { xPercent: currentPercent, duration: 1, ease: 'power3.out' }) // xPercent
    gsap.to(track, { width: trackWidth * zoomFactor, gap: gap * zoomFactor, duration: 0.5, ease: 'power1.in' }) // width
    gsap.to(explore, { y: -100, duration: 1, ease: 'power3.out' })
  }

  function zoomOut() {
    isZoomed = false
    setInfos(selectedContainer!, false)
    if (selectedContainer) selectedContainer.classList.remove('active')
    selectedContainer = null
    setBgColor(initialBgColor)

    gsap.to(track, { xPercent: currentPercent, duration: 1, ease: 'power3.in' }) // xPercent
    gsap.to(track, { width: trackWidth, duration: 1, gap: gap, ease: 'power3.out' }) // width
    gsap.to(explore, { y: 0, duration: 0.5, ease: 'power3.out' })
  }

  // --------------------------------------------------------------------------- Screens Navigation
  function toggleScreenVisibility(el: Element, elContent: Element, bool: boolean) {
    const opacity = bool ? 1 : 0
    const duration = bool ? 0.7 : 0.3
    const delay = bool ? 0 : 0
    const pointerEvents = bool ? 'auto' : 'none'

    gsap.to(el, { opacity, pointerEvents, duration, ease: 'power3.out' })
    gsap.to(elContent, { opacity, pointerEvents, duration, delay, ease: 'power3.out' })
  }

  function unmountScreen() {
    if (isZoomed) zoomOut()

    // Portfolio
    if (currentScreen === 'portfolio') {
      gsap.to(track, { height: '40px', width: '40vw', gap: '5px' })
      gsap.to(track, { xPercent: -100, duration: 1.3, ease: 'power3.out' })
      track.classList.add('mini')
    }
    // About
    else if (currentScreen === 'about') {
      toggleScreenVisibility(about, aboutContent, false)
    }
    // Hire Me
    else if (currentScreen === 'hire-me') {
      toggleScreenVisibility(hireMe, hireMeContent, false)
    }
    // Case Study
    else if (currentScreen === 'case-study') {
      gsap.to('.case-study', { display: ' none', height: 0 })
      gsap.to(scrollIndicator, { opacity: 0 })
      toggleScreenVisibility(caseStudy, caseStudyContent, false)
      setBgColor(initialBgColor)
    }
  }

  function mountScreen(newScreen: TScreenType) {
    activateObserver(newScreen === 'portfolio')

    // Portfolio
    if (newScreen === 'portfolio') {
      gsap.to(track, { height: '40vh', width: trackWidth, gap: '10px' })
      gsap.to(track, { xPercent: 0, duration: 1.3, ease: 'power3.out' })
      track.classList.remove('mini')
    }

    // About
    else if (newScreen === 'about') {
      toggleScreenVisibility(about, aboutContent, true)
    }

    // Hire Me
    else if (newScreen === 'hire-me') {
      toggleScreenVisibility(hireMe, hireMeContent, true)
    }

    // Case Study
    else if (newScreen === 'case-study') {
      const caseStudyGallery = caseStudyContent.querySelector(`.case-study[data-name="${currentCaseStudyName}"]`)
      const bgColor = (document.querySelector(`.container[data-name="${currentCaseStudyName}"]`) as HTMLElement)
        ?.dataset?.bgColor

      caseStudyGallery?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      gsap.to(caseStudyGallery, { display: 'flex', height: 'auto' })
      gsap.to(scrollIndicator, { opacity: 1 })
      if (bgColor) setBgColor(bgColor)
      toggleScreenVisibility(caseStudy, caseStudyContent, true)
    }
  }

  function switchScreen(newScreen: TScreenType) {
    if (currentScreen === newScreen) return

    unmountScreen()
    mountScreen(newScreen)
    currentScreen = newScreen
  }

  // --------------------------------------------------------------------------- Observer
  const observer = Observer.create({
    target: window,
    type: 'wheel, touch, scroll, pointer',
    onChange: (data) => handleTranslateTrack(data),
    preventDefault: true,
  })

  function activateObserver(bool: boolean) {
    if (bool) observer.enable()
    else observer.disable()
  }

  // --------------------------------------------------------------------------- Initialisation
  function init() {
    gsap.set(track, { xPercent: 100, width: trackWidth, gap })
    gsap.to(track, {
      xPercent: calculateContainerCenterPercentage(0, minScroll, maxScroll),
      duration: 0.7,
      ease: 'power3.out',
    })
    gsap.to(main, { backgroundColor: initialBgColor, duration: 3, ease: 'power3.out' })
  }

  function exploreAction() {
    if (currentCaseStudyLink) window.open(currentCaseStudyLink, '_blank')
    else switchScreen('case-study')
  }

  init()

  containers.forEach((c, index) => c.setAttribute('data-id', index.toString())) // Set data-id attributes on containers
  containers.forEach((c) => c.addEventListener('click', () => zoomIn(c as HTMLDivElement))) // Click events on containers

  aboutBtn.addEventListener('click', () => switchScreen('about'))
  portfolioBtn.addEventListener('click', () => switchScreen('portfolio'))
  hireMeBtn.addEventListener('click', () => switchScreen('hire-me'))
  explore.addEventListener('click', () => exploreAction())

  logo.addEventListener('click', () => {
    if (isZoomed) zoomOut()
    switchScreen('portfolio')
  })
})
